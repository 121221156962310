const SUBMIT_FORM_URL = './api.php?action=submitForm';

class Form {
  constructor(main) {
    if (!this.storeDomElements(main)) return;

    this.activeSubmit = false;

    this.initializeFormSubmit();
  }

  storeDomElements(main) {
    if (main.length < 1) return false;

    this.domElements = {};
    this.domElements.main = main;
    this.domElements.form = this.domElements.main.find('form');
    this.domElements.successMessage = this.domElements.main.find('.success-message');
    this.domElements.submitButton = this.domElements.main.find('submit-btn');
    return true;
  }

  initializeFormSubmit() {
    this.domElements.form.on('submit', this.onSubmitForm.bind(this));
  }

  onSubmitForm(event) {
    event.preventDefault();

    if (this.activeSubmit) {
      return;
    }

    this.activeSubmit = true;

    this.domElements.submitButton.fadeOut();

    $.post(SUBMIT_FORM_URL, {
      values: {
        subscriber_salutation: this.domElements.form.find('#subscriber-salutation').val(),
        subscriber_name: this.domElements.form.find('#subscriber-name').val(),
        subscriber_firstname: this.domElements.form.find('#subscriber-firstname').val(),
        subscriber_mail: this.domElements.form.find('#subscriber-mail').val(),
        datenschutz_akzeptiert: this.domElements.form.find('#datenschutz_akzeptiert').prop("checked"),
        widerrufsbelehrung_akzeptiert: this.domElements.form.find('#widerrufsbelehrung_akzeptiert').prop("checked")
      }

    }).then(result => {
      this.activeSubmit = false;

      switch (result.status) {
        case 'SUCCESS':
          this.domElements.form.hide();
          this.domElements.successMessage.fadeIn();
          break;
        case 'FAILURE':
          alert('Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.');
          break;
      }
    });
  }
}

export default Form;
