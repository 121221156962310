/*jshint esversion: 6 */

import $ from 'jquery';

import Form from './Form';


$(() => {
  $('.form-wrap').each((index, element) => {
    new Form($(element));
  });
});

